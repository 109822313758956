import React, { FC } from 'react';
import { Link } from 'gatsby';

import Image from 'common/Image';

import { FooterProps } from './models';

import './footer.scss';

const Footer: FC<FooterProps> = ({
  footer: {
    logo,
    copyright,
    contact: { contactLabel, email },
    socialMedia,
    links,
    textLegal: { text },
  },
}) => {
  const renderSocial = socialMedia.map((media) => {
    const { image, label: labelImage, url: urlMedia } = media.properties ? media.properties : media;
    const { url } = urlMedia[0];

    return (
      <div className="social__content" key={labelImage}>
        <p className="social__paragraph">{labelImage}</p>
        <a href={url} target="_blank" rel="noreferrer">
          <Image imageData={image} alt={labelImage} />
        </a>
      </div>
    );
  });
  const renderLinks = links.map((linksObject) => {
    const { label, navigationLink } = linksObject.properties ? linksObject.properties : linksObject;
    const { url } = navigationLink[0];

    return (
      <li className="menu__nav--item" key={label}>
        <Link className="menu__nav--link link" to={url}>
          {label}
        </Link>
      </li>
    );
  });

  return (
    <footer className="footer container-fluid">
      <div className="grid__row row">
        <div className="grid__col--legales col-sm-6 col-lg-6 my-2">
          <Image imageData={logo} alt={text} />
          <p className="legales__paragraph">{copyright}</p>
          <small className="legales__small">{text}</small>
        </div>
        <div className="grid__col--social col-sm-6 d-lg-none my-2">{renderSocial}</div>
        <div className="grid__col--contact col-sm-6 col-lg-3 mt-4 mb-2">
          <p className="contact__paragraph m-0">{contactLabel}</p>
          <a className="contact__link link" href={`mailto:${email}`}>
            {email}
          </a>
        </div>
        <div className="grid__col--menu col-sm-6 col-lg-3 my-2">
          <ul className="menu__nav">{renderLinks}</ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
