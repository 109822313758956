import React, { FC } from 'react';
import Helmet from 'react-helmet';

import { OneTrustCookiesProps } from './models';

const OneTrustCookies: FC<OneTrustCookiesProps> = ({ oneTrustID }) => (
  <Helmet>
    <script
      type="text/javascript"
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      data-document-language="true"
      data-domain-script="b6e5c4d5-1332-4bd8-a36e-fda5bca2920b"
    />
    <script
      type="application/json"
      src={`https://cdn.cookielaw.org/consent/${oneTrustID}/OtAutoBlock.js`}
    />
    <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
  </Helmet>
);

export default OneTrustCookies;
