import { graphql, useStaticQuery } from 'gatsby';

const getHomeQueryResults = () => {
  const query = useStaticQuery(graphql`
    {
      allHomePage {
        nodes {
          compositions {
            header {
              socialMediaTitle
              search {
                placeHolder
              }
              socialMedia {
                label
                url {
                  url
                }
                image {
                  childImageSharp {
                    gatsbyImageData(width: 30, height: 30)
                  }
                }
              }
              headerNavigation {
                navigation {
                  label
                  navigationLink {
                    name
                    url
                  }
                }
              }
              brandLogo {
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                link {
                  name
                  url
                }
              }
            }
            footer {
              logo {
                childImageSharp {
                  gatsbyImageData(width: 50, height: 50)
                }
              }
              textLegal {
                text
              }
              contact {
                contactLabel
                email
              }
              links {
                label
                navigationLink {
                  name
                  url
                  icon
                }
              }
              socialMedia {
                label
                image {
                  childImageSharp {
                    gatsbyImageData(width: 30, height: 30)
                  }
                }
                url {
                  name
                  target
                  url
                }
              }
              copyright
            }
            mainslider {
              banner {
                title
                banner {
                  link {
                    url
                  }
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            floatingbutton {
              button {
                image {
                  altText
                  fallbackUrl
                  url
                }
                link {
                  name
                  url
                }
              }
            }
          }
          content {
            title
            subTitle
            seoMetaTitle
            seoMetaDescription
            seoMetaKeywords
            seoAdditionalMeta {
              key
              value
            }
            homePageLink {
              image {
                childImageSharp {
                  gatsbyImageData(width: 96, height: 96)
                }
              }
              link {
                name
                url
              }
            }
            conditionalTextSlider {
              customClassName
              slider {
                hasText
                description
                layout
                buttonText
                customClassName
                title
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                imageMobile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                link {
                  url
                }
              }
            }
            brandLogoList {
              list {
                title
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                link {
                  url
                }
              }
              className
            }
          }
        }
      }
    }
  `);

  return query.allHomePage.nodes[0];
};
export default getHomeQueryResults;
