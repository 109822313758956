import React, { FC, useState } from 'react';
import { connectStateResults, Hits, InstantSearch, SearchBox } from 'react-instantsearch-dom';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import algoliasearch from 'algoliasearch/lite';

import { SearchProps } from './models';

import './SearchComponent.scss';

const appId = process.env.GATSBY_ALGOLIA_APP_ID || '';
const searchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY || '';
const indexName = process.env.GATSBY_ALGOLIA_INDEX_NAME || '';
const searchClient = algoliasearch(appId, searchKey);

const SearchComponent: FC<SearchProps> = ({ search: { placeHolder } }) => {
  const [initialState, setInitialState] = useState('');
  const Hit = ({ hit: { path, context } }) => (
    <Link to={path}>
      <p>{context.seoMetaTitle ? context.seoMetaTitle : ''}</p>
    </Link>
  );
  const Results = connectStateResults(({ searchState, searchResults, children }) =>
    searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <div className="ais-Hits">
        <FormattedMessage id="noResultsFound" />
        {searchState.query}
      </div>
    )
  );

  return (
    <>
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <SearchBox
          translations={{
            placeholder: placeHolder,
          }}
          onChange={(e) => {
            setInitialState(e.currentTarget.value);
          }}
          onReset={() => {
            setInitialState('');
          }}
        />
        {initialState ? (
          <Results>
            <Hits hitComponent={Hit} />
          </Results>
        ) : null}
      </InstantSearch>
    </>
  );
};
export default SearchComponent;
