import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import loadable from '@loadable/component';
import classNames from 'classnames';

import 'styles/main.scss';

import Footer from 'layout/Footer';
import Header from 'layout/Header';
import Loadable from 'components/Loadable';
import OneTrustCookies from 'components/OneTrustCookies';

import getHomeQueryResults from '../../templates/Home/getHomeQueryResults';
import { LayoutProps } from './models';

const PreviewWidget = loadable(() => import('components/PreviewWidget'));

const Layout: FC<LayoutProps> = ({
  children,
  headerTransparent,
  className,
  seoMetaTitle,
  seoMetaDescription,
  seoMetaKeywords,
  seoAdditionalMeta,
}) => {
  const CSSclassNames = classNames('layout', className, {
    'layout--transparent': headerTransparent,
  });
  const shouldLoadPreviewWidget = Boolean(Number(process.env.GATSBY_ENABLE_PREVIEW_WIDGET));
  const { compositions } = getHomeQueryResults();
  const { header, footer, floatingbutton } = compositions;

  const {
    siteSettings: { oneTrustID },
    siteSettings,
  } = useStaticQuery(graphql`
    query SettingsQ {
      siteSettings {
        siteName
        lang
        oneTrustID
      }
    }
  `);

  const siteLang = siteSettings.lang || process.env.GATSBY_LDS_LANGUAGE;

  return (
    <div className={CSSclassNames}>
      <Loadable isActive={shouldLoadPreviewWidget}>
        <PreviewWidget cmsUrl={`${process.env.GATSBY_UMBRACO_BASE_URL}/umbraco`} />
      </Loadable>
      <OneTrustCookies {...{ oneTrustID }} />
      <Helmet htmlAttributes={{ lang: siteLang }}>
        {seoMetaTitle && <meta name="title" content={seoMetaTitle} />}
        {seoMetaDescription && <meta name="description" content={seoMetaDescription} />}
        {seoMetaKeywords && <meta name="keywords" content={seoMetaKeywords} />}
        {seoAdditionalMeta
          ? seoAdditionalMeta.map(({ key, value }) => <meta name={key} content={value} />)
          : null}
      </Helmet>
      <Header header={header} floatingbutton={floatingbutton} />
      <main id="main" className="main">
        {children}
      </main>
      <Footer footer={footer} />
    </div>
  );
};

export default Layout;
