import React, { FC, useState } from 'react';
import { Link } from 'gatsby';

import SearchComponent from 'layout/SearchComponent/SearchComponent';
import Image from 'common/Image';

import LeftButtons from '../../components/LeftButtons';
import { HeaderProps } from './models';

import './Header.scss';

const Header: FC<HeaderProps> = ({
  header: { socialMedia, socialMediaTitle, brandLogo, headerNavigation, search },
  floatingbutton,
}) => {
  const [menuState, useMenuState] = useState('header__menu--close');
  const { navigation } = headerNavigation[0].properties
    ? headerNavigation[0].properties
    : headerNavigation[0];
  const onClick = () => {
    if (menuState === 'header__menu--close') {
      useMenuState('header__menu--open');
    } else {
      useMenuState('header__menu--close');
    }
  };

  return (
    <header className="header container-fluid">
      {floatingbutton ? <LeftButtons lba={floatingbutton} /> : <></>}
      <div className="header__menu--messages" />
      <div className={`header__menu ${menuState} `}>
        <div className="header__menu--mobile  d-md-none">
          <div className="grid__row row">
            {brandLogo.map((brand) => {
              const { image: BrandImage } = brand;
              const { url: urlLinks } = brand.link[0];

              return (
                <div key={urlLinks} className="logo gid__column col-auto">
                  <Link to={urlLinks} className="logo__link link">
                    <Image imageData={BrandImage} alt={urlLinks} />
                  </Link>
                </div>
              );
            })}
            <div className="gid__column col-3">
              <div className="menu__btn">
                <button
                  type="button"
                  aria-label="open"
                  className="menu__btn--open btn"
                  onClick={() => onClick()}
                />
                <button
                  type="button"
                  aria-label="close"
                  className="menu__btn--close btn"
                  onClick={() => onClick()}
                />
              </div>
            </div>
          </div>
          <ul className="nav">
            {navigation.map(({ label, navigationLink }) => {
              const { url: urlNav } = navigationLink[0];

              return (
                <li key={label} className="search nav__item">
                  <Link to={urlNav} className="nav__link link">
                    {label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="header__menu--desktop d-none d-md-block">
          <div className="grid__row row">
            <div className="grid__column col-9">
              {brandLogo.map((brandL) => {
                const { image: brandImageL } = brandL;
                const { url: urls } = brandL.link[0];

                return (
                  <div key={urls} className="logo d-inline-block">
                    <Link to={urls} className="logo__link link">
                      <Image imageData={brandImageL} alt={urls} />
                    </Link>
                  </div>
                );
              })}
            </div>
            <div className="grid__column col-3">
              {socialMedia && socialMedia[0] ? (
                <div className="container__social">
                  <p className="container__social--title">{socialMediaTitle}</p>
                  <a
                    href={
                      (socialMedia[0].properties ? socialMedia[0].properties : socialMedia[0])
                        .url[0].url
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      imageData={
                        (socialMedia[0].properties ? socialMedia[0].properties : socialMedia[0])
                          .image
                      }
                      alt={socialMediaTitle}
                    />
                  </a>
                </div>
              ) : (
                <></>
              )}

              <div>
                <SearchComponent search={search} />
              </div>
            </div>
            <div className="grid__column col-auto">
              <ul className="nav">
                {navigation.map(({ label, navigationLink }) => {
                  const { url: urlNavDesk } = navigationLink[0];

                  return (
                    <li key={label} className="nav__item">
                      <Link to={urlNavDesk} className="nav__link link">
                        {label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="grid__column col-auto container">
              <div className="container__search" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
