import React, { FC } from 'react';

import { FloatingButtons } from './model';

import './LeftButton.scss';

const Leftbuttons: FC<FloatingButtons> = ({ lba }) => (
  <div className="floating-button__container">
    {lba.button.map((button, index) => {
      if (!button?.image) return null;
      const { image, link } = button;
      if (!image) return null;
      const { url, altText } = image;

      return (
        <a
          key={index}
          href={link?.[0]?.url}
          className="floating-button"
          target="_blank"
          rel="noreferrer"
        >
          <img className="floating-button__img" src={url} alt={altText} />
        </a>
      );
    })}
  </div>
);

export default Leftbuttons;
